import { initialErrors } from "../Constants/Ads.constants";
import { Types } from "../Constants/Types";
import ProductType from "../Interfaces/products.interface";

const ProductsReducer = (state: ProductType['status'] = { 
        product: undefined,
        products: [],
        results: [],
        lproducts: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: ProductType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_PRODUCTS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_PRODUCTS:
            return {...state, products: action.payload, pages: action.pages};

        case Types.FETCH_PRODUCT:
            return {...state, product: action.payload};

        case Types.CREATE_PRODUCT:
            return {...state, products: [action.payload, ...state.products]};

        case Types.UPDATE_PRODUCT: 
            return {...state, products: state.products.map(product => product._id === action.payload.id ? action.payload : product)};
        
        case Types.STATE_PRODUCT: 
            return {...state, products: state.products.map(product => product._id === action.payload.id ? {...product, ...action.payload} : product), product: {...state.product, state: action.payload.state}};

        case Types.DELETE_PRODUCT: 
            return {...state, products: state.products.filter(product => product._id !== action.payload)}

        case Types.START_PRODUCTS:
            return {...state, lproducts: true};

        case Types.END_PRODUCTS:
            return {...state, lproducts: false};

        case Types.ERROR_PRODUCT:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_PRODUCT:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default ProductsReducer;