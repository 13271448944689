
export const initialErrors = { 
  firstName: String() || null,
  lastName: String() || null,
  phoneNumber: String() || null,
  email: String() || null,
  country: String() || null,
  city: String() || null,
  language: String() || null,
  currency: String() || null,
  password: String() || null
}

export const initialCustomer = {
  firstName: String() || null,
  lastName: String() || null,
  phoneNumber: String() || null,
  email: String() || null,
  password: String() || null,
  utilities: {
    country: "Iraq",
    city: "Baghdad",
    language: "ar",
    currency: "IQD"
  }
};

export type initialCustomerType = typeof initialCustomer;
export type initialErrorsType = typeof initialErrors;

export const customersTableHead = [
    {
      id: 'name',
      numeric: true,
      label: 'Name',
    },
    {
      id: 'phoneNumber',
      numeric: true,
      label: 'Phone Number',
    },
    {
      id: 'location',
      numeric: true,
      label: 'Location',
    },
    {
      id: 'state',
      numeric: true,
      label: 'Status',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'CreatedAt',
    },
    {
      id: '',
      numeric: false,
      label: '',
    },
  ];