import { initialErrors } from "../Constants/Orders.constants";
import { Types } from "../Constants/Types";
import OrderType from "../Interfaces/order-interface";

{/* //TODO: Check loading */}
const ScrapingOrdersReducer = (state: OrderType['status'] = { 
        order: undefined,
        orders: [],
        orderStatus: [],
        orderBrandsNotShipped: [],
        results: [],
        printOrders: [],
        lprint: false,
        lorders: false,
        lorder: false,
        loadStatus: false,
        loadBrands: false,
        pages: 0,
        resultPages: 0,
        badge: 0,
        error: initialErrors
    },
    action: OrderType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_ORDERS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_ORDERS:
            return {...state, orders: action.payload, pages: action.pages};

        case Types.FETCH_ORDER_STATUS:
            return {...state, orderStatus: action.payload};

        case Types.FETCH_ORDER_BRANDS_NOT_SHIPPED:
            return {...state, orderBrandsNotShipped: action.payload};

        case Types.FETCH_PRINT_ORDERS:
            return {...state, printOrders: action.payload};

        case Types.FETCH_ORDER:
            return {...state, order: action.payload};

        case Types.CREATE_ORDER:
            return {...state, orders: [action.payload, ...state.orders]};

        case Types.UPDATE_ORDER: 
            return {...state, orders: state.orders.map(order => order._id === action.payload.id ? action.payload : order)};
        
        case Types.STATE_ORDER: 
            return {...state, orders: state.orders.map(order => order._id === action.payload.id ? {...order, ...action.payload} : order), order: {...state.order, state: action.payload.state, subState: action.payload.state}};

        case Types.STATE_ORDERS: 
            return {...state, orders: state.orders.map(order => action.payload.ids.includes(order._id) ? {...order, subState: action.payload.state} : order)};

        case Types.DELETE_ORDER: 
            return {...state, orders: state.orders.filter(order => order._id !== action.payload)}

        case Types.START_ORDERS:
            return {...state, lorders: true};

        case Types.END_ORDERS:
            return {...state, lorders: false};

        case Types.START_ORDER:
            return {...state, lorder: true};

        case Types.END_ORDER:
            return {...state, lorder: false};

        case Types.START_ORDER_STATUS:
            return {...state, loadStatus: true};

        case Types.END_ORDER_STATUS:
            return {...state, loadStatus: false};

        case Types.START_ORDER_BRANDS_NOT_SHIPPED:
            return {...state, loadBrands: true};

        case Types.END_ORDER_BRANDS_NOT_SHIPPED:
            return {...state, loadBrands: false};

        case Types.START_PRINT_ORDERS:
            return {...state, lprint: true};

        case Types.END_PRINT_ORDERS:
            return {...state, lprint: false};

        case Types.ERROR_ORDER:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_ORDER:
            return {...state, error: initialErrors}

        case Types.ORDERS: 
            return {...state, badge: action.payload};

        default: 
            return state
    }
}

export default ScrapingOrdersReducer;