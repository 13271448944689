import { gql } from "@apollo/client";

export const loginAdmin = gql`
    mutation loginAdmin($loginAdminInput: LoginInput!) {
        loginAdmin(loginAdminInput: $loginAdminInput){
            _id
            firstName
            lastName
            phoneNumber
            email
            image
            type
            permissions{
                object
                abilities
            }
        }
    }
`

export const info = gql`
    query infoAdmin{
        infoAdmin{
            _id
            firstName
            lastName
            phoneNumber
            email
            image
            type
            permissions{
                object
                abilities
            }
        }
    }
`

export const refresh =gql`
    query refreshAdmin{
        refreshAdmin
    }
`

export const logoutGql = gql`
    mutation logoutAdmin{
        logoutAdmin
    }
`

export const mutationLoginDriver = gql`
    mutation loginDriver($loginDriverInput: LoginInput!) {
        loginDriver(loginDriverInput: $loginDriverInput){
            _id
            firstName
            lastName
            phoneNumber
            image
        }
    }
`

export const infoDriver = gql`
    query infoDriver{
        infoDriver{
            _id
            firstName
            lastName
            phoneNumber
            image
        }
    }
`

export const refreshDriver =gql`
    query refreshDriver{
        refreshDriver
    }
`

export const mutationLogoutDriver = gql`
    mutation logoutDriver{
        logoutDriver
    }
`

export const mutationUpdateProfile = gql`
    mutation updateDealerInfo($updateDealerInput: UpdateDealerInput!){
        updateDealerInfo(updateDealerInput: $updateDealerInput) {
            firstName
            lastName
            phoneNumber
            email
            image
        }
    }
`