import { initialErrors } from "../Constants/Categories.constants";
import { Types } from "../Constants/Types";
import ScrapingItemType from "../Interfaces/scraping-items.interface";

const ScrapingItemsReducer = (state: ScrapingItemType['status'] = { 
        scrapingItem: undefined,
        scrapingItemOrder: undefined,
        scrapingItems: [],
        results: [],
        lscrapingItems: false,
        pages: 0,
        resultPages: 0,
        error: initialErrors
    },
    action: ScrapingItemType['action']
    ) => {

    switch (action.type) {

        case Types.SEARCH_SCRAPING_ITEMS:
            return {...state, results: action.payload, resultPages: action.pages};

        case Types.FETCH_SCRAPING_ITEMS:
            return {...state, scrapingItems: action.payload, pages: action.pages};

        case Types.FETCH_SCRAPING_ITEM:
            return {...state, scrapingItem: action.payload};

        case Types.FETCH_SCRAPING_ITEM_ORDER:
            return {...state, scrapingItemOrder: action.payload};

        case Types.CREATE_SCRAPING_ITEM:
            return {...state, scrapingItems: [action.payload, ...state.scrapingItems]};

        case Types.UPDATE_SCRAPING_ITEM: 
            return {...state, scrapingItems: state.scrapingItems.map(scrapingItem => scrapingItem._id === action.payload.id ? action.payload : scrapingItem)};
        
        case Types.STATE_SCRAPING_ITEM: 
            return {...state, scrapingItems: state.scrapingItems.map(scrapingItem => scrapingItem._id === action.payload.id ? {...scrapingItem, ...action.payload} : scrapingItem), scrapingItem: {...state.scrapingItem, state: action.payload.state}};

        case Types.DELETE_SCRAPING_ITEM: 
            return {...state, scrapingItems: state.scrapingItems.filter(scrapingItem => scrapingItem._id !== action.payload)}

        case Types.START_SCRAPING_ITEMS:
            return {...state, lscrapingItems: true};

        case Types.END_SCRAPING_ITEMS:
            return {...state, lscrapingItems: false};

        case Types.ERROR_SCRAPING_ITEM:
            return {...state, error: action?.payload ? {...state.error, [action?.field!]: action.payload} : {...state.error, [action?.field!]: null}};

        case Types.RESET_SCRAPING_ITEM:
            return {...state, error: initialErrors}

        default: 
            return state
    }
}

export default ScrapingItemsReducer;