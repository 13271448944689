import React from 'react'
import { Avatar, Box, Grid, IconButton, InputAdornment, InputLabel, ListItemIcon, MenuItem, TextField } from '@mui/material'
import AuthTypes from '../../Interfaces/auth-interface'
import { useTranslation } from 'react-i18next';
import { Icons } from '../../Constants/Icons';
import { useDir } from '../../Contexts/DirectionContext';

const CustomInput = ({ 
    name,
    label,
    type,
    autoFocus,
    half,
    handleChange,
    handleShowPassword,
    selected,
    select,
    value,
    defaultValue,
    placeholder,
    disabled,
    helperText,
    error,
    className,
    multiline,
    rows,
    minRows,
    maxRows,
    required,
    ref,
    InputProps,
    inputComponent,
    countries,
    id,
    endComponent,
    small
}: Partial<AuthTypes['inputType']>) => {
    const { t } = useTranslation(["main"]);
    const { Globals } = useDir();

  return (
    <Grid item xs={12} sm={half ? 6 : 12} sx={{width: '100%'}}>
        <Box sx={{width: "100%", display: "flex", alignItems: "start", justifyContent: "center", flexDirection: "column", gap: 1}}>
            <InputLabel required={required} htmlFor={id} sx={{padding: "0 0 0 5px"}}>{t(label!)}</InputLabel>
            <Box sx={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                <TextField
                    defaultValue={defaultValue}
                    id={id}
                    ref={ref}
                    size={small ? "small" : "medium"}
                    multiline={multiline}
                    rows={rows}
                    minRows={minRows}
                    maxRows={maxRows}
                    dir={document.dir}
                    error={error}
                    name={name}
                    helperText={helperText}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={handleChange}
                    variant='outlined'
                    required={required}
                    fullWidth
                    autoFocus={autoFocus}
                    type={type}
                    select={select}
                    value={value}
                    className={className}
                    sx={{
                        '.MuiSelect-select': {
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                            padding: "16px 14px"
                        },
                    }}
                    InputProps= {name === 'password' || name === 'confirmPassword' ? {
                        endAdornment: (
                            <InputAdornment position={Globals.start as any}>
                                <IconButton onClick={handleShowPassword}>
                                    {type === 'password' ? <Icons.hide /> : <Icons.show />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position={Globals.end as any}>
                                <Icons.lock />
                            </InputAdornment>
                        )
                    } : InputProps}
                >
                    {selected?.map((option: AuthTypes["sectionInputType"], index :number) => (
                        <MenuItem key={index} value={option.value as any} disabled={option?.disabled} sx={{gap: "6px", color: option?.color}}>
                            {option?.icon && <ListItemIcon><option.icon/></ListItemIcon>}
                            {option?.image && <Avatar src={option?.src} sx={{height: 18, width: 18, background: "#fff"}} />}
                            {t(option.label)}
                        </MenuItem>
                    ))}
                    {countries?.map((option :any, index :number) => (
                        <MenuItem key={index} value={option.name}>
                            {option?.flag && <ListItemIcon><img src={option.flag} /></ListItemIcon>}
                            {t(option.name)}
                        </MenuItem>
                    ))}
                </TextField>
                {endComponent && endComponent }
            </Box>
        </Box>
    </Grid>
  )
}

export default CustomInput
